import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

interface FormErrorProps {
  message: string;
}

export const FormError = ({ message }: FormErrorProps) => {
  if (!message) return null;
  return (
    <div className='flex items-center space-x-2 bg-destructive/15 p-3 text-sm text-destructive dark:bg-destructive dark:text-destructive-foreground'>
      <ExclamationTriangleIcon />
      <span>{message}</span>
    </div>
  );
};
