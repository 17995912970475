import * as z from "zod";

export const LoginSchema = z.object({
  email: z.string().email({ message: "Email requis" }),
  password: z.string().min(1, { message: "Mot de passe requis" }),
  code: z.optional(z.string()),
});
export const RegisterSchema = z.object({
  email: z.string().email({ message: "Email requis" }),
  password: z.string().min(6, { message: "6 characters minimum" }),
  name: z.string().min(1, { message: "Nom requis" }),
  accepted_cgv: z.boolean(),
});
export const ResetSchema = z.object({
  email: z.string().email({ message: "Email requis" }),
});
export const NewPasswordSchema = z.object({
  password: z.string().min(6, { message: "6 characters minimum" }),
});
